<template>
  <div class="modal fade" :id="`${modalName}`" tabindex="-1" aria-labelledby="Label" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="modalName">
            {{ modalTitle }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body container">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal End -->
</template>

<script>
export default {
  name: 'BaseModal',
  props: ['modalName', 'modalTitle'],
};
</script>

<style scoped></style>
