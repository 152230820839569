import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import tracking from './modules/tracking';
import entries from './modules/entries';
import projects from './modules/projects';
import notifications from './modules/notifications';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    tracking,
    entries,
    projects,
    notifications,
  },
});
