<template>
  <div>
    <admin-layout v-if="$route.meta.layout == 'adminLayout'"></admin-layout>
    <div class="d-flex justify-content-center align-middle aligns-items-center align-self-center" v-else>
      <div class="spinner-grow text-warning" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { mapGetters, mapActions } from 'vuex';
import AdminLayout from './layouts/adminLayout.vue';

export default {
  name: 'App',
  components: {
    AdminLayout,
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['getUserData', 'updateUserAuth']),
  },
  created() {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        const userAuth = {
          uid: user.uid,
          userEmail: user.email,
        };
        this.updateUserAuth(userAuth);
        this.getUserData(userAuth.uid);
      }
    });
  },
};
</script>

<style>
body,
p,
a,
input,
select,
textarea,
button,
.btn {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
label,
.form-control {
  color: #666 !important;
}
.form-control:focus {
  color: #212529 !important;
  border-color: #212529 !important;
  box-shadow: 0 0 0 0.1rem rgb(33 37 41 / 27%) !important;
}
label,
input,
select,
textarea {
  width: 100%;
}
button,
.btn {
  border: 0 !important;
  outline: 0 !important;
  /* padding: 5px 25px !important;
  border-radius: 5px !important; */
}
.btn:focus,
button:focus {
  box-shadow: none !important;
}
.modal {
  z-index: 9999 !important;
}
</style>
