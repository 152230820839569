import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyCtlhMfli8stdzDuga0brUnRLSj0nUkLSM',
  authDomain: 'tracker2-b4cd7.firebaseapp.com',
  projectId: 'tracker2-b4cd7',
  storageBucket: 'tracker2-b4cd7.appspot.com',
  messagingSenderId: '1059628215773',
  appId: '1:1059628215773:web:eebe79257cfe4652d71a34',
};

// init firebase app
initializeApp(firebaseConfig);

export const db = getFirestore();
