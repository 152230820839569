const state = {
  notifications: [],
};
const getters = {
  getNotifications: (state) => state.notifications,
};
const actions = {
  addNotification: ({ commit }, notification) => commit('addNotification', notification),
  removeNotification: ({ commit }, notification) => commit('removeNotification', notification),
};
const mutations = {
  addNotification: (state, notification) => state.notifications.push(notification),
  removeNotification: (state, notification) => {
    return (state.notifications = state.notifications.filter((item) => item.id != notification.id));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
