<template>
  <div id="tasks-list" class="mt-3">
    <div v-for="(groupedTasks, index) in groupedTasksByDate" :key="index">
      <div class="date-section d-flex justify-content-between">
        <div class="date">
          {{ index }}
        </div>
        <div class="total-duration">
          {{ totalDurationPerDay(groupedTasks) }}
        </div>
      </div>
      <div v-for="task in groupedTasks" :key="task.id" class="task">
        <div class="row task-list-item">
          <div
            class="task-description col-md-10 col-9"
            @click="modalEditShow(task)"
            data-bs-toggle="modal"
            data-bs-target="#modalEdit"
          >
            <h4>{{ taskTitle(task) }}</h4>
            <p><i class="fas fa-briefcase" /> {{ getProject(task) ? getProject(task) : 'n/a' }}</p>
          </div>

          <div class="task-controls col-md-2 col-3">
            <div class="d-flex justify-content-around align-items-center h-100">
              <h4 class="mb-0">
                {{ getHmsFromSeconds(task.duration) }}
              </h4>
              <button class="text-white" :disabled="isTrackingActivated" @click="continueTask(task.id)">
                <i class="fas fa-play fa-2x" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-modal modalTitle="View/Edit entry" modalName="modalEdit">
      <template #body>
        <div class="row">
          <div class="col-md-12">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="title" placeholder="" v-model="tempEditTask.title" />
              <label for="title">Title</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                placeholder=""
                id="description"
                v-model="tempEditTask.description"
              ></textarea>
              <label for="description">Description</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input v-model="tempEditTask.date" type="date" class="form-control" />
              <label for="date">Date</label>
            </div>
          </div>
          <div class="col-md-4 col-6">
            <div class="form-floating mb-3">
              <input
                v-model="tempEditTask.startTime"
                @change="modalEditTimeDuration(tempEditTask)"
                type="text"
                class="form-control"
                placeholder="h:m:s"
              />
              <label for="startTime">Start time</label>
            </div>
          </div>
          <div class="col-md-4 col-6">
            <div class="form-floating mb-3">
              <input
                v-model="tempEditTask.endTime"
                @change="modalEditTimeDuration(tempEditTask)"
                type="text"
                class="form-control"
              />
              <label for="endTime">End time</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <select v-model="tempEditTask.project" class="form-control">
                <option disabled value="">Please select one</option>
                <option v-for="project in projects" :key="project.id" :value="project.id">
                  {{ project.project }}
                </option>
              </select>
              <label>Project</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3" v-if="tempEditTask">
              <input type="text" class="form-control" :value="getHmsFromSeconds(tempEditTask.duration)" disabled />
              <label for="duration">Duration</label>
            </div>
          </div>
        </div>
      </template>
      <template #buttons>
        <button class="btn bg-danger text-white" data-bs-dismiss="modal" @click="remove(tempEditTask.id)">
          Delete
        </button>
        <button
          type="button"
          class="btn btn-success text-white"
          data-bs-dismiss="modal"
          @click="modalSave(tempEditTask)"
        >
          Save changes
        </button>
      </template>
    </base-modal>
  </div>
</template>

<script>
import * as _ from 'lodash';
import BaseModal from './BaseModal.vue';
export default {
  components: { BaseModal },
  name: 'EntryList',
  props: ['tasks', 'projects', 'isTrackingActivated'],
  data() {
    return {
      tempEditTask: '',
    };
  },
  computed: {
    groupedTasksByDate() {
      let sortedTasks = _.sortBy(this.tasks, ['date', 'timeStamp']);
      sortedTasks = sortedTasks.reverse();
      const groupedTasks = _.groupBy(sortedTasks, (task) => task.date);
      return groupedTasks;
    },
  },
  methods: {
    remove(id) {
      const result = confirm('Are you sure you want to delete?');
      if (result) {
        this.$emit('delete-task', id);
      }
    },
    edit(id) {
      const task = this.tasks.filter((task) => task.id == id);
      this.$emit('edit-task', task[0]);
    },
    continueTask(id) {
      const task = this.tasks.filter((task) => task.id == id);
      console.log(task);
      this.$emit('continue-task', task[0]);
    },
    getProject(task) {
      const getProjects = this.projects
        .filter((project) => project.id === task.project)
        .map((el) => el.project)
        .toString();
      return getProjects;
    },
    taskTitle(task) {
      if (!task.title) return 'No title';
      else if (task.title.length > 45) return task.title.substring(0, 45) + '...';
      else return task.title;
    },
    taskDescription(task) {
      if (task.description == null) return 'No description';
      else if (task.description.length > 120) return task.description.substring(0, 120) + '...';
      else return task.description;
    },
    modalEditShow(task) {
      this.tempEditTask = { ...task };
    },
    modalSave(task) {
      console.log(task);
      const duration = this.getDurationDiff(task.startTime, task.endTime);

      const savedTask = {
        id: task.id,
        title: task.title,
        description: task.description,
        date: task.date,
        startTime: task.startTime,
        endTime: task.endTime,
        duration: duration,
        project: task.project,
        selectedProject: task.project,
        timeStamp: task.timeStamp,
      };
      this.$emit('save-task', savedTask);
    },
    modalEditTimeDuration(task) {
      this.tempEditTask.duration = this.getDurationDiff(task.startTime, task.endTime);
    },
    totalDurationPerDay(tasks) {
      let total = tasks.reduce((prev, curr) => parseInt(prev) + parseInt(curr?.duration), 0);
      return this.getHmsFromSeconds(total);
    },
  },
};
</script>

<style scoped>
.task {
  position: relative;
  border: 1px solid #e5e5e5;
  color: #666666;
  padding: 10px;
  margin-bottom: 10px;
  background: #e4e4e4;
}
.task h4 {
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
}
.task p {
  padding: 0;
  margin: 0;
  line-height: 1.5rem;
  font-size: 14px;
}
.task-description:hover {
  cursor: pointer;
}
.task-description .task-meta {
  font-size: 12px;
}
[disabled] {
  cursor: not-allowed;
  opacity: 0.2;
}
.date-section {
  font-weight: bold;
  font-size: 16px;
  background: #fdbd40;
  padding: 5px 10px;
}
.task-controls button {
  margin: 0;
  padding: 10px !important;
  border-radius: 50% !important;
  background: transparent;
}
.task-controls i {
  font-size: 1.5em;
  color: #212529;
}

@media (max-width: 987px) {
  #tasks-list {
    margin-top: 35px !important;
  }
  .task-meta span {
    width: 33% !important;
    display: inline-block;
  }
  .task-controls h4 {
    font-size: 14px;
  }
  .task-controls button {
    padding: 8px !important;
  }
  .task-controls i {
    font-size: 14px;
  }
}
</style>
