import { db } from '@/firebase/init';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
const state = {
  auth: {},
  data: {},
  settings: {},
};
const getters = {
  user: (state) => state,
};
const actions = {
  getUserData: ({ commit }) => {
    const userRef = doc(db, 'users', state.auth.uid);
    getDoc(userRef).then((doc) => {
      commit('setUserData', doc);
    });
  },
  updateUserData: ({ commit }, data) => {
    const uid = state.auth.uid;
    const userRef = doc(db, 'users', uid);
    updateDoc(userRef, {
      ...data,
    }).then(() => {
      commit('updateUserData', data);
      alert('updated');
    });
  },
  updateUserAuth: ({ commit }, data) => {
    commit('updateUserAuth', data);
  },
};
const mutations = {
  setUserData: (state, user) => {
    return (state.data = user.data());
  },
  updateUserData: (state, data) => (state.data = data),
  updateUserAuth: (state, data) => (state.auth = { ...data }),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
