import { db } from '@/firebase/init';
import { collection, getDocs, addDoc, doc, deleteDoc, updateDoc } from 'firebase/firestore';
const state = {
  projects: [],
};
const getters = {
  projects: (state) => state.projects,
};
const actions = {
  async getProjects({ commit }) {
    const uid = this.state.user.auth.uid;
    const projectsRef = await collection(db, `users/${uid}/projects`);
    await getDocs(projectsRef).then((snapshot) => {
      const projects = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      commit('setProjects', projects);
    });
  },
  addProject({ commit }, entry) {
    const uid = this.state.user.auth.uid;
    const colRef = collection(db, `users/${uid}/projects`);
    addDoc(colRef, {
      project: entry.project,
      client: entry.client,
      perHour: entry.perHour,
      perHourCurrency: entry.perHourCurrency,

      address: entry.address,
      city: entry.city,
      country: entry.country,
      website: entry.website,
      email: entry.email,
    }).then((data) => {
      commit('addProject', { entry, data });
    });
  },
  updateProject({ commit }, project) {
    const uid = this.state.user.auth.uid;
    const projectRef = doc(db, `users/${uid}/projects`, project.id);
    updateDoc(projectRef, {
      project: project.project,
      client: project.client,
      perHour: project.perHour,
      perHourCurrency: project.perHourCurrency,

      address: project.address,
      city: project.city,
      country: project.country,
      website: project.website,
      email: project.email,
    }).then(() => {
      commit('updateProject', project);
    });
  },
  removeProject({ commit }, id) {
    const uid = this.state.user.auth.uid;
    const projectRef = doc(db, `users/${uid}/projects`, id);
    deleteDoc(projectRef).then(() => {
      commit('removeProject', id);
    });
  },
};
const mutations = {
  setProjects(state, projects) {
    return (state.projects = projects);
  },
  addProject(state, data) {
    const temp = { ...data.entry, id: data.data.id };
    return state.projects.unshift(temp);
  },
  removeProject(state, id) {
    return (state.projects = state.projects.filter((entry) => entry.id !== id));
  },
  updateProject(state, temp) {
    const index = state.projects.findIndex((project) => project.id === temp.id);
    if (index !== -1) {
      state.projects.splice(index, 1, temp);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
