<template>
  <div>
    <button class="sidebar-menu" @click="toggleSidebarMenu"><i class="fas fa-bars text-white fa-2x"></i></button>
    <div id="sidebar" class="p-3 text-white bg-dark" :class="{ 'show-sidebar-menu': showAdminSidebar }">
      <router-link
        to="/admin/tracker"
        class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
      >
        <img src="../assets/images/logo.png" alt="TimeTracker" class="app-logo" />
      </router-link>
      <hr />
      <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item">
          <router-link
            to="/admin/tracker"
            :class="[{ active: $route.path == '/admin/tracker' }, 'nav-link']"
            aria-current="page"
          >
            <i class="fas fa-home"></i>
            Tracker
          </router-link>
        </li>
        <li>
          <router-link to="/admin/projects" :class="[{ active: $route.path == '/admin/projects' }, 'nav-link']">
            <i class="fas fa-briefcase"></i>
            Projects
          </router-link>
        </li>
        <li>
          <router-link to="/admin/profile" :class="[{ active: $route.path == '/admin/profile' }, 'nav-link']">
            <i class="fas fa-user-circle"></i>
            Profile
          </router-link>
        </li>
        <!-- <li>
          <router-link to="/admin/invoices" :class="[{ active: $route.path == '/admin/invoices' }, 'nav-link']">
            <i class="fas fa-file-invoice"></i>
            Invoices
          </router-link>
        </li> -->
        <li>
          <router-link to="/admin/reports" :class="[{ active: $route.path == '/admin/reports' }, 'nav-link']">
            <i class="far fa-flag"></i>
            Reports
          </router-link>
        </li>
        <li>
          <a @click.prevent="signingOut" class="nav-link" href="#">
            <i class="fa fa-sign-out"></i>
            Sign out
          </a>
        </li>
      </ul>
      <hr />
      <p class="copyrights text-center mt-15">TimeTracker © {{ new Date().getFullYear() }}. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth';
import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['showAdminSidebar'],
  name: 'TheSidebar',
  data() {
    return {
      // isSidebarActive: false,
    };
  },
  computed: {
    ...mapGetters(['user']),
    isSidebarActive() {
      return false;
    },
  },
  methods: {
    ...mapActions(['getUserData', 'updateUserAuth']),
    signingOut() {
      signOut(getAuth())
        .then(() => {
          const userAuth = {
            uid: null,
            userEmail: null,
          };
          this.updateUserAuth(userAuth);
          this.$emit('signing-out');
          this.$router.replace({ name: 'Home' });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    toggleSidebarMenu() {
      console.log('sidebar menu clicked');
      // this.isSidebarActive = !this.isSidebarActive;
      this.$emit('toggle-admin-sidebar');
    },
  },
};
</script>

<style scope>
img.app-logo {
  width: 100%;
}
#sidebar {
  position: sticky;
  top: 0;
  left: 0;
}
.nav-pills .nav-link {
  color: white;
}
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:hover,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #394d5cb8 !important;
}
.nav-pills .nav-link:hover {
  color: #fff;
  background-color: #394d5c3b !important;
}
#sidebar ul li a {
  font-size: 16px !important;
}

#sidebar ul li a i {
  margin-right: 10px;
  font-size: 21px;
  position: relative;
}
.sidebar-menu {
  display: none;
}
.copyrights {
  position: fixed;
  bottom: 0;
  width: 17%;
  left: 0;
  font-size: 13px;
  color: #666;
}

@media (max-width: 987px) {
  .sidebar-menu {
    background: #212529;
    color: #333;
    display: block;
    position: fixed;
    top: 5px;
    right: 10px;
    padding: 10px 15px !important;
    z-index: 9999;
  }
  button.sidebar-menu i {
    font-size: 1rem;
  }
  .show-sidebar-menu {
    display: block !important;
    transition: all 0.5s ease;
    position: fixed;
    z-index: 9999;
  }
  img.app-logo {
    width: 250px;
    margin: 0 auto;
  }
  .copyrights {
    position: relative;
    left: unset;
    bottom: unset;
    width: 100%;
  }
  #sidebar {
    display: none;
    transition: all 0.5s ease;
    position: fixed;
    height: 100%;
    top: unset;
    left: unset;
  }
  #sidebar img.app-logo {
    margin-bottom: 35px;
  }
  #sidebar ul li {
    text-align: center;
  }
}
</style>
