const state = {
  // from Tracker.vue
  isTrackingActive: false,
  // from TaskAdd.vue
  isTrackingActivated: false,
  isTrackingOfflineActive: false,
  // from TaskCounter.vue (these two are changing only if isTrackingActivated from TaskAdd.vue is changed)
  trackingTiming: null,
  trackingStartTimeFull: null, // get throw setInterval
  trackingStartTime: null,
  trackingEndTime: null,
  //
  trackingTask: {},
};
const getters = {
  isTrackingActive: (state) => state.isTrackingActive,
  isTrackingOfflineActive: (state) => state.isTrackingOfflineActive,
  isTrackingActivated: (state) => state.isTrackingActivated,
  trackingTiming: (state) => state.trackingTiming,
  trackingStartTimeFull: (state) => state.trackingStartTimeFull,
  trackingStartTime: (state) => state.trackingStartTime,
  trackingEndTime: (state) => state.trackingEndTime,
  trackingTask: (state) => state.trackingTask,
};
const actions = {};
const mutations = {
  SET_IS_TRACKING_ACTIVE: (state, value) => (state.isTrackingOfflineActive = value),
  SET_IS_TRACKING_OFFLINE_ACTIVE: (state, value) => (state.isTrackingOfflineActive = value),
  SET_IS_TRACKING_ACTIVATED: (state, value) => (state.isTrackingActivated = value),
  SET_TRACKING_TIMING: (state, value) => (state.trackingTiming = value),
  SET_TRACKING_START_TIME_FULL: (state, value) => (state.trackingStartTimeFull = value),
  SET_TRACKING_START_TIME: (state, value) => (state.trackingStartTime = value),

  SET_TRACKING_END_TIME: (state, value) => (state.trackingEndTime = value),
  SET_TRACKING_TASK: (state, value) => (state.trackingTask = value),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
