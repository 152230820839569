<template>
  <div id="add-entry-container" class="mt-3 mb-3">
    <div class="row mb-3">
      <div class="col-md-2 col-4 d-flex align-items-center">
        <keep-alive>
          <entry-counter :is-tracking-activated="isTrackingActivated" class="" />
        </keep-alive>
      </div>

      <div class="col-md-10 col-8">
        <div class="form-floating">
          <input v-model="newTask.title" class="form-control" placeholder="Type new task..." />
          <label>Task name</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="add-entry-description mb-3 description-section">
        <div class="form-floating">
          <textarea v-model="newTask.description" class="form-control" placeholder="Description" />
          <label>Task description</label>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-3 col-6 add-entry-date">
        <div class="form-floating">
          <input v-model="newTask.date" type="date" class="form-control" />
          <label>Date</label>
        </div>
      </div>
      <div class="col-md-2 col-6 add-entry-time">
        <div class="form-floating">
          <input v-model="newTask.startTime" type="text" class="form-control" placeholder="h:m:s" disabled />
          <label> Start time</label>
        </div>
      </div>
      <div class="col-md-3 col-12 add-entry-project">
        <div class="form-floating">
          <select v-model="newTask.project" class="form-control">
            <option disabled value="">Please select one</option>
            <option v-for="project in projects" :key="project.id" :value="project.id">
              {{ project.project }}
            </option>
          </select>
          <label>Project</label>
        </div>
      </div>
      <div class="col-md-4 col-12 d-md-flex align-items-center add-entry-actions">
        <div class="btn-group" role="group">
          <button v-if="!isTrackingActivated" class="btn bg-success text-white" @click="startTracking()">
            <i class="far fa-play-circle" /> Start
          </button>
          <button v-if="!isTrackingActivated" class="btn bg-secondary text-white" style="" @click="reset()">
            <i class="fa fa-refresh" /> Reset
          </button>
          <button v-else-if="isTrackingActivated" class="btn bg-danger text-white" @click="endTracking()">
            <i class="far fa-stop-circle" /> End
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntryCounter from './EntryCounter.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'EntryAdd',
  components: { EntryCounter },
  props: ['projects', 'tempTask'],
  data() {
    return {
      newTask: {
        id: '',
        title: '',
        description: '',
        date: '',
        startTime: '',
        endTime: '',
        duration: 0,
        project: '',
        selectedProject: '',
        timeStamp: '',
      },
    };
  },
  computed: {
    ...mapGetters(['isTrackingActivated', 'trackingStartTime', 'trackingEndTime', 'trackingTask']),
  },
  watch: {
    tempTask() {
      if (this.isTrackingActivated) {
        // for CONTINUE option
        this.sync(this.tempTask);
        this.$store.commit('SET_TRACKING_TASK', this.newTask);
        window.sessionStorage.setItem('SET_TRACKING_TASK', JSON.stringify(this.newTask));
        this.startTracking();
      }
    },
    trackingStartTime() {
      this.newTask.startTime = this.trackingStartTime;
    },
    newTask: {
      handler: function (value) {
        this.$store.commit('SET_TRACKING_TASK', value);
        window.sessionStorage.setItem('SET_TRACKING_TASK', JSON.stringify(value));
      },
      deep: true,
    },
  },
  mounted() {
    // sync fields on changing routes
    if (this.isTrackingActivated) {
      this.sync(this.trackingTask);
      this.newTask.startTime = this.trackingStartTime;
    } else {
      this.sync(this.trackingTask);
    }

    window.addEventListener('scroll', function () {
      // console.log(this.window.innerWidth);

      if (this.window.innerWidth < 987) {
        if (window.scrollY >= 1) {
          document.getElementById('add-entry-container').classList.add('fixed-topp');
        } else {
          document.getElementById('add-entry-container').classList.remove('fixed-topp');
        }
      } else if (window.scrollY >= 200) {
        document.getElementById('add-entry-container').classList.add('fixed-topp');
      } else {
        document.getElementById('add-entry-container').classList.remove('fixed-topp');
      }
    });
  },
  created() {
    // sync fields on a page reload
    const temp = JSON.parse(window.sessionStorage.getItem('SET_TRACKING_TASK'));
    this.newTask = temp;
    this.$store.commit('SET_TRACKING_TASK', temp);
  },
  methods: {
    startTracking() {
      this.$store.commit('SET_IS_TRACKING_ACTIVATED', true);
      this.$store.commit('SET_IS_TRACKING_OFFLINE_ACTIVE', true);
      window.sessionStorage.setItem('SET_IS_TRACKING_OFFLINE_ACTIVE', true);

      this.newTask.startTime = this.trackingStartTime;
      this.newTask.timeStamp = Date.now();
      this.newTask.date = new Date().toISOString().slice(0, 10);
      this.$emit('task-tracking', this.isTrackingActivated);
    },
    endTracking() {
      const endTime = this.getCurrentTime();
      this.$store.commit('SET_TRACKING_END_TIME', endTime);

      this.newTask.endTime = endTime;

      this.newTask.duration = this.getDurationDiff(this.newTask.startTime, this.newTask.endTime);

      if (!this.newTask.date) this.newTask.date = this.getCurrentDate();

      const task = {
        title: this.newTask.title,
        description: this.newTask.description,
        date: this.newTask.date,
        startTime: this.newTask.startTime,
        endTime: this.newTask.endTime,
        duration: this.newTask.duration,
        project: this.newTask.project,
        selectedProject: this.newTask.project,
        timeStamp: this.newTask.timeStamp,
      };

      this.$emit('add-task', task);
      this.$emit('task-tracking', this.isTrackingActivated);

      this.reset();
      this.$store.commit('SET_TRACKING_START_TIME', null);
      this.$store.commit('SET_TRACKING_END_TIME', null);
      this.$store.commit('SET_IS_TRACKING_ACTIVATED', false);
      this.$store.commit('SET_IS_TRACKING_OFFLINE_ACTIVE', false);
      window.sessionStorage.removeItem('SET_TRACKING_START_TIME');
      window.sessionStorage.removeItem('SET_IS_TRACKING_OFFLINE_ACTIVE');
    },
    reset() {
      this.newTask.id = '';
      this.newTask.title = '';
      this.newTask.description = '';
      this.newTask.date = '';
      this.newTask.startTime = '';
      this.newTask.endTime = '';
      this.newTask.duration = 0;
      this.newTask.selectedProject = '';
      this.newTask.project = '';
      this.timeStamp = '';
    },
    sync(task) {
      this.newTask = { ...task };
      this.newTask.selectedProject = task.selectedProject;
      this.newTask.project = task.project;
    },
  },
};
</script>

<style scoped>
div#add-entry-container.fixed-topp {
  background: white;
  box-shadow: 1px 3px 6px #ccc;
  padding: 15px 0 !important;
  margin: 0 !important;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
div#add-entry-container.fixed-topp .row:first-child {
  padding: 0 15px;
  margin-bottom: 0 !important;
}
div#add-entry-container.fixed-topp .row:not(:first-child) {
  display: none;
}
.add-entry-actions .btn-group button {
  padding: 18px 25px;
}
input,
textarea,
select {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
textarea {
  height: 80px;
}
@media (max-width: 987px) {
  .add-entry-project,
  .add-entry-date {
    margin-bottom: 15px;
  }
  .add-entry-actions .btn-group {
    width: 100%;
  }
  #add-entry-container .row:first-child button {
    font-size: 12px !important;
    padding: 0 !important;
  }
  .add-entry-actions button {
    padding: 10px 25px !important;
  }
}
</style>
