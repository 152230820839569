<template>
  <div>
    <entry-add
      :projects="projects"
      :temp-task="state.tempTask"
      :is-tracking-activated="isTrackingActivated"
      @task-tracking="taskTracking($event)"
      @add-task="add($event)"
    />
    <entry-list
      v-if="entries.length"
      :tasks="entries"
      :projects="projects"
      :is-tracking-activated="isTrackingActivated"
      @edit-task="edit($event)"
      @continue-task="continueTask($event)"
      @delete-task="remove($event)"
      @save-task="save($event)"
    />
    <div v-else class="text-center mt-5">No entries. Start tracking your activities.</div>

    <div v-if="getNotifications.length" style="z-index: 9999; position: fixed; bottom: 10px; right: 10px">
      <div
        v-for="item in getNotifications"
        :key="item.id"
        class="alert d-flex align-items-center"
        :class="`alert-${item.type}`"
        role="alert"
      >
        <div>{{ item.message }}</div>
        <button @click="removeNotification(item)" type="button" class="btn-close"></button>
      </div>
    </div>
  </div>
</template>

<script>
import EntryAdd from '@/components/EntryAdd.vue';
import EntryList from '@/components/EntryList.vue';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Tracker',
  components: { EntryList, EntryAdd },
  data: () => ({
    state: {
      tempTask: {},
      notification: {
        type: '',
        message: '',
      },
    },
  }),
  computed: {
    ...mapGetters(['entries', 'projects', 'user', 'isTrackingActivated', 'getNotifications']),
  },
  methods: {
    ...mapActions([
      'getEntries',
      'addEntry',
      'removeEntry',
      'updateEntry',
      'getProjects',
      'addNotification',
      'removeNotification',
    ]),
    taskTracking(status) {
      this.$store.commit('SET_IS_TRACKING_ACTIVATED', status);
    },
    add(newTask) {
      this.addEntry(newTask)
        .then(() => {
          const notification = {
            id: Date.now(),
            type: 'success',
            message: 'Entry is successfully added.',
          };
          this.$store.dispatch('addNotification', notification);
        })
        .catch((error) => {
          const notification = {
            id: Date.now(),
            type: 'danger',
            message: 'Entry is not added.',
          };
          this.$store.dispatch('addNotification', notification);
          console.log(error.message);
        });
    },
    edit(task) {
      this.state.tempTask = task;
    },
    continueTask(task) {
      this.state.tempTask = { ...task };
      this.state.tempTask.startTime = '';
      this.state.tempTask.endTime = '';
      this.state.tempTask.duration = '';
      this.state.tempTask.date = new Date().toISOString().slice(0, 10);
      this.state.timeStamp = Date.now();

      this.$store.commit('SET_IS_TRACKING_ACTIVATED', true);
    },
    remove(id) {
      this.removeEntry(id)
        .then(() => {
          const notification = {
            id: Date.now(),
            type: 'success',
            message: 'Entry is successfully removed.',
          };
          this.$store.dispatch('addNotification', notification);
        })
        .catch((error) => {
          const notification = {
            id: Date.now(),
            type: 'danger',
            message: 'Entry is not removed.',
          };
          this.$store.dispatch('addNotification', notification);
          console.log(error.message);
        });
    },
    save(task) {
      this.updateEntry(task)
        .then(() => {
          const notification = {
            id: Date.now(),
            type: 'success',
            message: 'Entry is successfully updated.',
          };
          this.$store.dispatch('addNotification', notification);
        })
        .catch((error) => {
          const notification = {
            id: Date.now(),
            type: 'danger',
            message: 'Entry is not updated.',
          };
          this.$store.dispatch('addNotification', notification);
          console.log(error.message);
        });
    },
  },
  created() {
    this.getEntries();
    this.getProjects();

    // for tracking after page reload
    const trackingStartTimeFull = window.sessionStorage.getItem('SET_TRACKING_START_TIME_FULL');
    if (trackingStartTimeFull) {
      const trackingStartTime = window.sessionStorage.getItem('SET_TRACKING_START_TIME');
      const trackingTiming = window.sessionStorage.getItem('SET_TRACKING_TIMING');

      this.$store.commit('SET_TRACKING_START_TIME_FULL', trackingStartTimeFull);

      this.$store.commit('SET_TRACKING_START_TIME', trackingStartTime);
      this.$store.commit('SET_TRACKING_TIMING', trackingTiming);
      this.$store.commit('SET_IS_TRACKING_OFFLINE_ACTIVE', true);
      this.$store.commit('SET_IS_TRACKING_ACTIVATED', true);
    }
  },
};
</script>
