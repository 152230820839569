<template>
  <div class="home">
    <register />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Register from '@/views/Register';
export default {
  name: 'Home',
  components: {
    Register,
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['getUserData']),
  },
  created() {
    if (this.user.auth.uid) this.$router.push('/admin/tracker');
    else this.$router.push('/register');
  },
};
</script>
