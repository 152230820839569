import Vue from 'vue';
import VueRouter from 'vue-router';
import Tracker from '../views/admin/Tracker.vue';
import Home from '../views/Home.vue';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: 'adminLayout' },
  },
  {
    path: '/admin/',
    redirect: '/admin/tracker',
  },
  {
    path: '/admin/tracker',
    name: 'Tracker',
    component: Tracker,
    meta: { requiresAuth: true, layout: 'adminLayout' },
  },
  {
    path: '/admin/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/admin/Profile.vue'),
    meta: { requiresAuth: true, layout: 'adminLayout' },
  },
  {
    path: '/admin/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "settings" */ '../views/admin/Invoices.vue'),
    meta: { requiresAuth: true, layout: 'adminLayout' },
  },
  {
    path: '/admin/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/admin/Reports.vue'),
    meta: { requiresAuth: true, layout: 'adminLayout' },
  },
  {
    path: '/admin/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/admin/Projects.vue'),
    meta: { requiresAuth: true, layout: 'adminLayout' },
  },
  {
    path: '/admin/projects/:id',
    name: 'ProjectEdit',
    meta: { requiresAuth: true, layout: 'adminLayout' },
    component: () => import(/* webpackChunkName: "project" */ '../views/admin/ProjectEdit.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: { layout: 'adminLayout' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { layout: 'adminLayout' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  onAuthStateChanged(getAuth(), (user) => {
    if (requiresAuth) {
      if (!user) {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath,
          },
        });
      } else {
        if (to.name === 'Login' || to.name === 'Register') next({ name: 'Tracker' });
        next();
      }
    } else if (!requiresAuth) {
      if (user) {
        if (to.name === 'Login' || to.name === 'Register') next({ name: 'Tracker' });
      }
      next();
    } else {
      next();
    }
  });
});

export default router;
