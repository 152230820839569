<template>
  <div class="container-fluid">
    <div class="row">
      <the-admin-sidebar
        id="sidebar-container"
        class="col-md-2"
        :showAdminSidebar="showAdminSidebar"
        @toggle-admin-sidebar="toggleAdminSidebar"
      />
      <div id="main-area" class="col-md-10" @click="toggleAdminSidebarGlobal">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import TheAdminSidebar from '@/components/TheAdminSidebar.vue';
export default {
  components: {
    TheAdminSidebar,
  },
  data() {
    return {
      showAdminSidebar: false,
    };
  },
  methods: {
    toggleAdminSidebar() {
      this.showAdminSidebar = !this.showAdminSidebar;
    },
    toggleAdminSidebarGlobal() {
      if (this.showAdminSidebar) {
        this.showAdminSidebar = !this.showAdminSidebar;
      }
    },
  },
};
</script>

<style scoped>
#main-area {
  background: #00000005;
  min-height: 100vh;
}
#sidebar-container {
  padding: 0;
  min-height: 100vh;
  /* height: 100%; */
  background: #212529;
}

@media (max-width: 987px) {
  #sidebar-container {
    padding: 0;
    margin: 0;
    min-height: unset;
    height: unset;
    background: unset;
  }
}
</style>
