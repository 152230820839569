<template>
  <button>{{ days }}d {{ hours }}h {{ minutes }}m {{ seconds }}s</button>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'EntryCounter',
  props: ['isTrackingActivated'],
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  watch: {
    isTrackingActivated: {
      handler(value) {
        if (value) {
          this.startTimer();
        } else {
          this.stopTimer();
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      'trackingTiming',
      'trackingStartTimeFull',
      'trackingStartTime',
      'trackingEndTime',
      'isTrackingOfflineActive',
    ]),
  },
  mounted() {
    if (this.trackingStartTimeFull) {
      const trackingStartTimeFull = new Date(this.trackingStartTimeFull);
      this.timer(trackingStartTimeFull);
    }
  },
  methods: {
    startTimer() {
      const trackingStartTimeFull = new Date();
      const trackingStartTime = this.getCurrentTime();
      this.$store.commit('SET_TRACKING_START_TIME_FULL', trackingStartTimeFull);
      this.$store.commit('SET_TRACKING_START_TIME', trackingStartTime);
      window.sessionStorage.setItem('SET_TRACKING_START_TIME_FULL', trackingStartTimeFull);
      window.sessionStorage.setItem('SET_TRACKING_START_TIME', trackingStartTime);

      this.timer(trackingStartTimeFull);
    },
    stopTimer() {
      clearInterval(this.trackingTiming);
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
      this.$store.commit('SET_TRACKING_TIMING', null);
      this.$store.commit('SET_TRACKING_START_TIME_FULL', null);
      window.sessionStorage.removeItem('SET_TRACKING_TIMING');
      window.sessionStorage.removeItem('SET_TRACKING_START_TIME_FULL');
    },
    timer(trackingStartTimeFull) {
      const trackingTiming = setInterval(() => {
        const now = new Date().getTime();

        const distance = now - trackingStartTimeFull.getTime();

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);

      this.$store.commit('SET_TRACKING_TIMING', trackingTiming);
      window.sessionStorage.setItem('SET_TRACKING_TIMING', trackingTiming);
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
  text-align: center;
  background: #fdbd40;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  height: 57px;
}
</style>
