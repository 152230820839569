import { db } from '@/firebase/init';
import { collection, getDocs, addDoc, doc, deleteDoc, updateDoc } from 'firebase/firestore';
const state = {
  entries: [],
};
const getters = {
  entries: (state) => state.entries,
};
const actions = {
  getEntries({ commit }) {
    const uid = this.state.user.auth.uid;

    const entriesRef = collection(db, `users/${uid}/entries`);
    getDocs(entriesRef).then((snapshot) => {
      const entries = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      commit('setEntries', entries);
    });
  },
  addEntry({ commit }, entry) {
    const uid = this.state.user.auth.uid;
    const colRef = collection(db, `users/${uid}/entries`);
    addDoc(colRef, {
      title: entry.title || null,
      description: entry.description || null,
      date: entry.date,
      startTime: entry.startTime,
      endTime: entry.endTime,
      duration: entry.duration,
      project: entry.project || null,
      timeStamp: entry.timeStamp,
    }).then((data) => {
      if (data) {
        const dataID = data.id;
        commit('addEntry', { entry, dataID });
      } else {
        throw Error('Something went wrong.');
      }
    });
  },
  updateEntry({ commit }, task) {
    const uid = this.state.user.auth.uid;
    const entryRef = doc(db, `users/${uid}/entries`, task.id);
    updateDoc(entryRef, {
      title: task.title || null,
      description: task.description || null,
      date: task.date,
      startTime: task.startTime,
      endTime: task.endTime,
      duration: task.duration,
      project: task.project || null,
    }).then(() => {
      // save data to state
      commit('updateEntry', task);
    });
  },
  removeEntry({ commit }, id) {
    const uid = this.state.user.auth.uid;
    const entryRef = doc(db, `users/${uid}/entries`, id);

    deleteDoc(entryRef).then(() => {
      commit('removeEntry', id);
    });
  },
};
const mutations = {
  setEntries(state, entries) {
    return (state.entries = entries);
  },
  addEntry(state, data) {
    const temp = { ...data.entry, id: data.dataID };
    return state.entries.unshift(temp);
  },
  removeEntry(state, id) {
    return (state.entries = state.entries.filter((entry) => entry.id !== id));
  },
  updateEntry(state, task) {
    const index = state.entries.findIndex((entry) => entry.id === task.id);
    if (index !== -1) {
      state.entries.splice(index, 1, task);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
