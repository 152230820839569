import Vue from 'vue';
Vue.mixin({
  methods: {
    getDurationDiff(start, end) {
      const timeStart = start.split(':').reduce((acc, time) => 60 * acc + +time);
      const timeEnd = end.split(':').reduce((acc, time) => 60 * acc + +time);

      const hourDiff = timeEnd - timeStart;

      return hourDiff;
    },
    formatDate(value) {
      return value.split('-').reverse().join('-');
    },
    getCurrentTime() {
      const now = new Date();
      const time = `${now
        .getHours()
        .toString()
        .replace(/^(\d)$/, '0$1')}:${now
        .getMinutes()
        .toString()
        .replace(/^(\d)$/, '0$1')}:${now
        .getSeconds()
        .toString()
        .replace(/^(\d)$/, '0$1')}`;
      return time;
    },
    getCurrentDate() {
      const date = new Date();
      const todayDate = date.toISOString().slice(0, 10);
      return todayDate;
    },
    getHmsFromSeconds(seconds) {
      return new Date(seconds * 1000).toISOString().substring(11, 19);
    },
  },
});
