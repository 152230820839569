<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 mx-auto mt-5">
        <div class="panel panel-info">
          <div class="panel-heading">
            <div class="panel-title">Register</div>
          </div>

          <div style="padding-top: 30px" class="panel-body">
            <div v-if="error" id="login-alert" class="alert alert-danger col-sm-12">
              {{ error }}
            </div>

            <form class="form-horizontal row">
              <div class="col-md-6 mb-3">
                <input v-model="user.firstName" type="text" class="form-control" placeholder="First name" />
              </div>
              <div class="col-md-6 mb-3">
                <input v-model="user.lastName" type="text" class="form-control" placeholder="Last name" />
              </div>
              <div class="col-md-6 mb-3">
                <input v-model="user.address.street" type="text" class="form-control" placeholder="Street" />
              </div>
              <div class="col-md-6 mb-3">
                <input v-model="user.address.zip" type="text" class="form-control" placeholder="ZIP" />
              </div>
              <div class="col-md-6 mb-3">
                <input v-model="user.address.city" type="text" class="form-control" placeholder="City" />
              </div>
              <div class="col-md-6 mb-3">
                <select v-model="user.address.country" class="form-control">
                  <option disabled value="">Select country</option>
                  <option v-for="country in countries" :key="country.code" :value="country.code">
                    {{ country.name }}
                  </option>
                </select>
              </div>

              <div class="col-md-6 mb-3">
                <input v-model="user.email" type="email" class="form-control" placeholder="Email address" />
              </div>
              <div class="col-md-6 mb-3">
                <input v-model="user.password" type="password" class="form-control" placeholder="Password" />
              </div>

              <div style="margin-top: 10px" class="form-group">
                <div class="col-sm-12 controls">
                  <button class="btn btn-success" @click.prevent="register">Register</button>
                </div>
              </div>

              <div class="form-group mt-3">
                <div class="col-md-12 control">
                  <div style="border-top: 1px solid#888; padding-top: 15px; font-size: 85%">
                    Have an account?
                    <router-link to="/login"> Log in </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { countries } from '@/assets/js/countries';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Register',
  data() {
    return {
      countries,
      loggedIn: false,
      user: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        address: {
          street: '',
          zip: '',
          city: '',
          country: '',
        },
      },
      error: '',
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['updateUserAuth']),
    register() {
      const db = getFirestore();
      createUserWithEmailAndPassword(getAuth(), this.user.email, this.user.password)
        .then((cred) => {
          const userRef = doc(db, 'users', cred.user.uid);
          setDoc(userRef, {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            userID: cred.user.uid,
            address: {
              street: this.user.address.street,
              zip: this.user.address.zip,
              city: this.user.address.city,
              country: this.user.address.country,
            },
          });
        })
        .then((cred) => {
          const userAuth = {
            uid: cred.user.id,
            userEmail: cred.user.email,
          };
          this.updateUserAuth(userAuth);
          this.$router.push({ name: 'Tracker' });
        })
        .catch((err) => {
          console.log(err.message);
          this.error = 'Something went wrong. Please try later.';
          this.$router.push({ name: 'Register' });
        });
    },
  },
};
</script>

<style></style>
